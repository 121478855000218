import { FC, memo, SyntheticEvent, useMemo } from 'react'
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  HomeIcon,
  LeaveIcon,
  MessagesIcon,
  ShiftsIcon,
  TeamIcon,
} from '../../display'
import { NavItem } from './navItem'
import { useDialog } from '@/hooks'

const BottomNav: FC = () => {
  const location = useLocation()
  const { isOpen: isDialogOpen, closeDialog } = useDialog()
  const currentTab =
    location.pathname.split('/').length > 1
      ? '/' + location.pathname.split('/')[1]
      : '/leave' // TODO: roll back this to be /home once the home page is ready
  const navigate = useNavigate()

  // This has to be part of the component itself because of the dynamic
  // badge icon content for the Messages menu icon
  const navItems: NavItem[] = useMemo(
    () => [
      {
        label: 'Home',
        value: '/home',
        Icon: HomeIcon,
      },
      { label: 'Shifts', value: '/shifts', Icon: ShiftsIcon },
      {
        label: 'Messages',
        value: '/messages',
        Icon: MessagesIcon,
      },
      { label: 'Leave', value: '/leave', Icon: LeaveIcon },
      { label: 'Team', value: '/team', Icon: TeamIcon },
    ],
    [],
  )

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    if (currentTab === newValue) return

    if (isDialogOpen) {
      closeDialog()
    }

    navigate(newValue)
  }

  return (
    <BottomNavigation
      value={currentTab}
      onChange={handleChange}
      showLabels
      key="bottom-nav"
      sx={{
        // This is to ensure the bottom nav is always on top of the dialog,
        // by default the MUI dialog is zIndex: 1300
        zIndex: 1350,
        paddingBottom: '5px',
        '& .MuiButtonBase-root': {
          padding: 0,
          minWidth: 'unset',
        },
      }}
    >
      {navItems.map(({ label, value, Icon: IconComponent }) => {
        const isSelected = currentTab === value
        return (
          <BottomNavigationAction
            key={value}
            label={<Typography variant="bottomNav">{label}</Typography>}
            value={value}
            icon={<IconComponent />}
            disableRipple
            aria-label={`Go to ${label.toLowerCase()} page`} // Label the icon for A11Y
            aria-current={isSelected ? 'page' : undefined} // Mark active item for A11Y
          />
        )
      })}
    </BottomNavigation>
  )
}

export default memo(BottomNav)
