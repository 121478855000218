import { createTheme } from '@mui/material/styles'
import { PaletteMode, Theme } from '@mui/material'
import { BOTTOM_NAV_HEIGHT, TABS_HEIGHT } from './constants'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bottomNav: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    bottomNav?: React.CSSProperties
  }

  interface PaletteColor {
    background?: string // Add background property to the PaletteColor
  }

  interface Palette {
    disable: PaletteColor
  }

  interface TypeText {
    tertiary: string // Add tertiary text color
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bottomNav: true
  }
}

// Define any colors which could be used in multiple places in theme here
const textFontSize = 14

const colors = {
  light: {
    // Green palette
    primary: {
      main: '#2C984A',
      light: '#D1F3DB',
      background: '#F1FAF1',
    },
    // Brown palette
    secondary: {
      main: '#AD9D50',
      light: '#EEEBD4',
      background: '#FAF9F2',
    },
    // Grey palette
    disable: {
      main: '#A5A5A5',
      light: '#DFDFDF',
      dark: '#757575',
      background: '#F6F6F6',
    },
    text: {
      primary: '#2B2B2B', // Black
      secondary: '#2B2B2B66', // #2B2B2B with 40% opacity
      tertiary: '#B4B5B5', // eg. Arrows, Dots, etc in cards
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    bottomNav: {
      default: '#D7D7D7',
    },
  },
}

export const getDesignTokens = (mode: PaletteMode) =>
  ({
    palette: {
      mode,
      primary: {
        main: colors[mode].primary.main,
        light: colors[mode].primary.light,
        background: colors[mode].primary.background,
      },
      secondary: {
        main: colors[mode].secondary.main,
        light: colors[mode].secondary.light,
        background: colors[mode].secondary.background,
      },
      disable: {
        main: colors[mode].disable.main,
        light: colors[mode].disable.light,
        dark: colors[mode].disable.dark,
        background: colors[mode].disable.background,
      },
      background: {
        default: colors[mode].background.default,
        paper: colors[mode].background.paper,
      },
      text: {
        primary: colors[mode].text.primary,
        secondary: colors[mode].text.secondary,
        tertiary: colors[mode].text.tertiary,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            height: '50px',
            borderRadius: '12px',
            textTransform: 'none', // Disable uppercase transformation globally
            '.MuiTypography-root': {
              letterSpacing: '0.1px',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: colors[mode].disable.background,
            width: '100%',
            height: TABS_HEIGHT,
            color: 'text.primary',
            position: 'fixed',

            '& .MuiTab-root': {
              textTransform: 'none',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiBackdrop-root': {
              background: 'rgba(43, 43, 43, 0.22)',
            },
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            alignContent: 'center',
            backgroundColor: 'background.paper',
            borderTop: `1px solid ${colors[mode].bottomNav.default}`,
            boxShadow: '0px -3px 4px 0px rgba(0, 0, 0, 0.05)',
            height: BOTTOM_NAV_HEIGHT,

            '& .MuiButtonBase-root': {
              color: colors[mode].bottomNav.default,

              '& .MuiSvgIcon-root': {
                fontSize: '1.8rem',
                color: colors[mode].bottomNav.default,
              },

              '& .Mui-selected': {
                color: colors[mode].primary.main,
              },

              '&.Mui-selected .MuiSvgIcon-root': {
                color: colors[mode].primary.main,
                '&.MuiSvgIcon-root': {
                  color: colors[mode].primary.main,
                },
              },
            },
          },
        },
      },
    },
  }) as unknown as Theme

// Set up some customisation for MUI components that can be rendered by React
const getTheme = (mode: PaletteMode) =>
  createTheme({
    ...getDesignTokens(mode),
    typography: {
      fontSize: textFontSize,
      fontFamily: 'Avenir LT Std',
      // e.g. Tab header labels
      h1: {
        color: colors[mode].text.primary,
        fontFamily: 'Avenir LT Std',
        fontSize: '1.75rem', // 28px,
        fontStyle: 'normal',
        fontWeight: 750,
        lineHeight: 'normal',
        letterSpacing: '-0.0125rem', // -0.2px
      },
      subtitle1: {
        color: colors[mode].text.primary,
        fontFamily: 'Avenir LT Std',
        fontSize: '1.25rem', // 20px
        fontStyle: 'normal',
        fontWeight: 750,
        lineHeight: 'normal',
        letterSpacing: '-0.0125rem', // -0.2px
      },
      // e.g. Section titles
      body1: {
        color: colors[mode].text.primary,
        fontFamily: 'Avenir LT Std',
        fontSize: '1rem', // 16px
        fontStyle: 'normal',
        fontWeight: 750,
        lineHeight: 'normal',
        letterSpacing: '-0.0125rem', // -0.2px
      },
      // e.g. default text
      body2: {
        color: colors[mode].text.primary,
        fontFamily: 'Avenir LT Std',
        fontSize: '0.9375rem', // 15px
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '-0.00625rem', // -0.1px
      },
      // Bottom nav labels
      bottomNav: {
        fontFamily: 'Avenir LT Std',
        fontSize: '0.875rem', // 14px
        fontStyle: 'normal',
        fontWeight: 750,
        lineHeight: 'normal',
        letterSpacing: '0.00625rem', // 0.1px
      },
    },
    shape: {
      borderRadius: 12,
    },
  })

export default getTheme
