//TODO: Remove this once we are no longer dependent on MercuryAPI
/**
 * This file and its methods have been added because it was not possible to return
 * a union type in the automatically generated file from NSwag or through the Swashbuckle
 * specifications. PLEASE DO NOT USE THESE METHODS UNLESS ABSOLUTELY NECESSARY.
 * They are specifically implemented to support retrieving the staff member, which
 * the only case in this system where a polymorphic response is required.
 */

import { QueryOptions } from '@tanstack/react-query'
import { mercuryApiErrorHandler } from './helper'
import {
  AuthError,
  getAuthorizationData,
  getCompanyId,
  logger,
  singletonQueryClient,
} from '@/utils'

const getDefaultHeaders = () => {
  const authData = getAuthorizationData()
  const defaultHeaders: object = {
    authorization: `Bearer ${authData.accessToken}`,
    'x-loaded-company-id': getCompanyId(),
  }

  return defaultHeaders
}

/**
 * Execute a GET query against a given API.
 *
 * @param apiUrl the base url of the API
 * @param path the path of endpoint
 * @param options QueryOptions from React Query (without queryKey and queryFn)
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function get<T>(
  apiUrl: string,
  path: string,
  options?: Omit<QueryOptions<T>, 'queryKey' | 'queryFn'>,
  signal?: AbortSignal,
) {
  return singletonQueryClient.fetchQuery<T>({
    ...options,
    queryKey: [apiUrl, path, signal],
    queryFn: async () => {
      // Normalise the path and remove a leading slash, we don't want to end up
      // with two of these when constructing the url as the API won't tolerate
      // these, instead returning a 404.
      const trimmedPath = path.replace(/^\//, '')

      const defaultHeaders = getDefaultHeaders()

      const response = await fetch(`${apiUrl}/${trimmedPath}`, {
        signal: signal,
        headers: {
          ...defaultHeaders,
        },
      })

      if (!response.ok) {
        logger.error(
          `received HTTP ${response.status} requesting data from ${path}`,
        )

        if (response.status === 401) {
          throw new AuthError('API client is not authenticated')
        }

        await mercuryApiErrorHandler(response)
      }

      return response.json()
    },
  })
}

/**
 * Execute a POST against a given API.
 *
 * @param apiUrl the base url of the API
 * @param path the path of the endpoint
 * @param requestBody the body of the POST request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function add(
  apiUrl: string,
  path: string,
  requestBody: object | FormData,
  signal?: AbortSignal,
) {
  // Normalise the path and remove a leading slash, we don't want to end up
  // with two of these when constructing the url as the API won't tolerate
  // these, instead returning a 404.
  const trimmedPath = path.replace(/^\//, '')

  const defaultHeaders = getDefaultHeaders()

  const headers: HeadersInit = {
    ...defaultHeaders,
    Accept: 'application/json',
  }

  if (requestBody instanceof FormData) {
    delete headers['Content-Type']
  } else {
    headers['Content-Type'] = 'application/json;charset=utf-8'
  }

  const response = await fetch(`${apiUrl}/${trimmedPath}`, {
    signal: signal,
    method: 'POST',
    headers: headers,
    body:
      requestBody instanceof FormData
        ? requestBody
        : JSON.stringify(requestBody),
  })

  if (!response.ok) {
    logger.error(`received HTTP ${response.status} sending data to ${path}`)

    if (response.status === 401) {
      throw new AuthError('API client is not authenticated')
    }

    return mercuryApiErrorHandler(response)
  }
  const contentType = response.headers.get('Content-Type')
  if (contentType?.includes('application/json')) {
    return response.json()
  }
  return
}

/**
 * Execute a PUT against a given API.
 *
 * @param apiUrl the base url of the API
 * @param path the path of the endpoint
 * @param requestBody the body of the PUT request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function update(
  apiUrl: string,
  path: string,
  requestBody: object,
  signal?: AbortSignal,
) {
  // Normalise the path and remove a leading slash, we don't want to end up
  // with two of these when constructing the url as the API won't tolerate
  // these, instead returning a 404.
  const trimmedPath = path.replace(/^\//, '')

  const defaultHeaders = getDefaultHeaders()

  const response = await fetch(`${apiUrl}/${trimmedPath}`, {
    signal: signal,
    method: 'PUT',
    headers: {
      ...defaultHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(requestBody),
  })
  if (!response.ok) {
    logger.error(`received HTTP ${response.status} sending data to ${path}`)

    if (response.status === 401) {
      throw new AuthError('API client is not authenticated')
    }

    return mercuryApiErrorHandler(response)
  }
  return response.status === 204 ? '' : response.json()
}

/**
 * Execute a DELETE agaisnt a given API.
 *
 * @param apiUrl the base url of the API
 * @param path the path of the endpoint
 * @param requestBody the body of the DELETE request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function remove(
  apiUrl: string,
  path: string,
  requestBody?: object,
  signal?: AbortSignal,
) {
  // Normalise the path and remove a leading slash, we don't want to end up
  // with two of these when constructing the url as the API won't tolerate
  // these, instead returning a 404.
  const trimmedPath = path.replace(/^\//, '')

  const defaultHeaders = getDefaultHeaders()

  const response = await fetch(`${apiUrl}/${trimmedPath}`, {
    signal: signal,
    method: 'DELETE',
    headers: {
      ...defaultHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(requestBody),
  })
  if (!response.ok) {
    logger.error(`received HTTP ${response.status} sending data to ${path}`)

    if (response.status === 401) {
      throw new AuthError('API client is not authenticated')
    }

    return mercuryApiErrorHandler(response)
  }
  return response.status === 204 ? '' : response.json()
}

/**
 * Execute a PATCH against a given API.
 *
 * @param apiUrl the base url of the API
 * @param path the path of the endpoint
 * @param requestBody the body of the PATCH request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function patch(
  apiUrl: string,
  path: string,
  requestBody: object,
  signal?: AbortSignal,
) {
  // Normalise the path and remove a leading slash, we don't want to end up
  // with two of these when constructing the url as the API won't tolerate
  // these, instead returning a 404.
  const trimmedPath = path.replace(/^\//, '')

  const defaultHeaders = getDefaultHeaders()

  const response = await fetch(`${apiUrl}/${trimmedPath}`, {
    signal: signal,
    method: 'PATCH',
    headers: {
      ...defaultHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(requestBody),
  })
  if (!response.ok) {
    logger.error(`received HTTP ${response.status} sending data to ${path}`)

    if (response.status === 401) {
      throw new AuthError('API client is not authenticated')
    }

    return mercuryApiErrorHandler(response)
  }
  return response.status === 204 ? '' : response.json()
}

export default {
  get,
  add,
  update,
  remove,
  patch,
}
