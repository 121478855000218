import { StateCreator } from 'zustand'
import { IStaffMembersDetailSummaryResponse } from '@/models'
import { staffMemberService } from '@/services'
import { exceptionMessageHandler } from '@/utils'
import { SharedSlice } from '@/components/sharedSlice'
import { Dayjs } from 'dayjs'

// Team slice types
interface TeamState {
  staffMembers: IStaffMembersDetailSummaryResponse[]
}

export interface TeamSlice extends TeamState {
  resetTeamSlice: () => void

  /**
   * Get Staff Members list
   * @param includeDeleted
   */
  getStaffMembers: (time?: Dayjs, includeDeleted?: boolean) => Promise<void>
}

const initialState: TeamState = {
  staffMembers: [],
}

export const createTeamSlice: StateCreator<
  TeamSlice & SharedSlice,
  [['zustand/devtools', never]],
  [],
  TeamSlice
> = (set, _get) => ({
  ...initialState,

  resetTeamSlice: () => set(initialState),

  getStaffMembers: (
    time?: Dayjs,
    includeDeleted: boolean = true,
  ): Promise<void> => {
    return staffMemberService
      .getAll(time, includeDeleted)
      .then((result) => {
        set(() => ({ staffMembers: result }))
      })
      .catch((error) => {
        set(() => ({
          staffMembers: [],
        }))
        exceptionMessageHandler(error, 'Failed to retrieve staff members')
      })
      .finally(() => {
        set(() => ({ isLoading: false }))
      })
  },
})
