import useAppStore from '@/state/store'
import { getActiveCompanySettings, logger } from '@/utils'
import { defer } from 'react-router-dom'
import { Dayjs } from 'dayjs'
import { initialState as shiftsSliceInitialState } from '../shiftsSlice'

export default async function loader() {
  logger.debug('[loader] My Shifts loader')

  // On page load it will always be the current week
  const { dayStartPreference } = getActiveCompanySettings()
  const [hours, minutes, seconds] = dayStartPreference.split(':').map(Number)

  const dateRangeWithCompanyStartTime: [Dayjs, Dayjs] = [
    shiftsSliceInitialState.myShiftsSelectedDateRange[0]
      .set('hour', hours)
      .set('minute', minutes)
      .set('second', seconds)
      .set('milliseconds', 0),
    shiftsSliceInitialState.myShiftsSelectedDateRange[1]
      .set('hour', hours)
      .set('minute', minutes)
      .set('second', seconds)
      .set('milliseconds', 0),
  ]

  useAppStore
    .getState()
    .setMyShiftsSelectedDateRange(dateRangeWithCompanyStartTime)

  const rosteredShifts = useAppStore
    .getState()
    .getRosteredShiftsByDateRange(dateRangeWithCompanyStartTime)

  const clockins = useAppStore
    .getState()
    .getClockinsByDateRange(dateRangeWithCompanyStartTime)

  return defer({
    data: Promise.all([rosteredShifts, clockins])
      .then(() => true)
      .catch((error) => {
        logger.error('My Shifts Promise.all error', error)
        return false
      }),
  })
}
