import { StateCreator } from 'zustand'
import { SharedSlice } from '@/components/sharedSlice'
import dayjs, { Dayjs } from 'dayjs'
import { rosterService, timeClockinService } from '@/services'
import { exceptionMessageHandler, logger } from '@/utils'
import { IClockinResponse, IRosteredShiftResponse } from '@/models'
import { getCurrentMondayToSundayRange } from '@/components/common'
import { isCancelledError } from '@tanstack/react-query'
import { MyShiftsToggleButtonOption } from './my-shifts/_common/helpers/myShiftsToggleButtonOption'

// Shifts slice types
interface ShiftsState {
  myShiftsSelectedDateRange: [Dayjs, Dayjs]
  myShiftsSelectedDays: string[]
  myShiftsTab: MyShiftsToggleButtonOption
  rosteredShifts: IRosteredShiftResponse[]
  clockins: IClockinResponse[]
  fetchingRosteredShifts: boolean
  fetchingClockins: boolean
  rosterSelectedDate: Dayjs
}

export interface ShiftsSlice extends ShiftsState {
  resetShiftsSlice: () => void

  setMyShiftsSelectedDateRange: (dateRange: [Dayjs, Dayjs]) => void

  setMyShiftsSelectedDays: (date: string) => void

  setMyShiftsTab: (tab: MyShiftsToggleButtonOption) => void

  setFetchingRosteredShifts: (fetching: boolean) => void

  setFetchingClockins: (fetching: boolean) => void

  setRosterSelectedDate: (date: Dayjs) => void

  getRosteredShiftsByDateRange: (dateRange: [Dayjs, Dayjs]) => Promise<void>

  getClockinsByDateRange: (dateRange: [Dayjs, Dayjs]) => Promise<void>
}

const currentMondayToSundayRange = getCurrentMondayToSundayRange(dayjs())

export const initialState: ShiftsState = {
  myShiftsSelectedDateRange: [
    currentMondayToSundayRange[0],
    currentMondayToSundayRange[1],
  ],
  myShiftsSelectedDays: [],
  myShiftsTab: 'roster',
  rosteredShifts: [],
  clockins: [],
  fetchingRosteredShifts: false,
  fetchingClockins: false,
  rosterSelectedDate: dayjs(),
}

export const createShiftsSlice: StateCreator<
  ShiftsSlice & SharedSlice,
  [['zustand/devtools', never]],
  [],
  ShiftsSlice
> = (set, _get) => ({
  ...initialState,

  resetShiftsSlice: () => set(initialState),

  setMyShiftsSelectedDateRange: (dateRange: [Dayjs, Dayjs]) =>
    set({ myShiftsSelectedDateRange: dateRange }),

  setMyShiftsSelectedDays: (date: string) =>
    set((state) => {
      const isDateSelected = state.myShiftsSelectedDays.includes(date)
      return {
        myShiftsSelectedDays: isDateSelected
          ? state.myShiftsSelectedDays.filter((d) => d !== date) // Remove the date if already selected
          : [...state.myShiftsSelectedDays, date], // Add the date if not selected
      }
    }),

  setMyShiftsTab: (tab: MyShiftsToggleButtonOption) =>
    set({ myShiftsTab: tab }),

  setFetchingRosteredShifts: (fetching: boolean) =>
    set({ fetchingRosteredShifts: fetching }),

  setFetchingClockins: (fetching: boolean) =>
    set({ fetchingClockins: fetching }),

  setRosterSelectedDate: (date: Dayjs) => set({ rosterSelectedDate: date }),

  getRosteredShiftsByDateRange: (dateRange: [Dayjs, Dayjs]): Promise<void> => {
    set(() => ({ fetchingRosteredShifts: true }))

    return rosterService
      .getRosteredShifts(
        undefined,
        dateRange[0],
        dateRange[1].add(1, 'day'), // Add 1 day to the end date to include the last day of the COMPANY week
        false,
        false,
      )
      .then((rosteredShifts) => {
        if (!rosteredShifts) {
          throw new Error('Service failed to get rostered shifts')
        }

        set({
          rosteredShifts: rosteredShifts,
        })
      })
      .catch((error) => {
        if (isCancelledError(error)) {
          logger.debug('Rostered Shifts query was cancelled')
          // We do a return here as we don't want to update the state if the query was cancelled,
          // otherwise it would be an empty array until the last call finishes and return the values
          return
        }

        set({
          rosteredShifts: [],
        })

        exceptionMessageHandler(error)
      })
      .finally(() => {
        set(() => ({ fetchingRosteredShifts: false }))
      })
  },

  getClockinsByDateRange: (
    dateRange: [Dayjs, Dayjs],
    includeInactive: boolean = false,
    includeLiveCalculation: boolean = false,
  ): Promise<void> => {
    set(() => ({ fetchingClockins: true }))

    return timeClockinService
      .getClockins(
        dateRange[0],
        dateRange[1].add(1, 'day'), // Add 1 day to the end date to include the last day of the COMPANY week
        includeInactive,
        includeLiveCalculation,
      )
      .then((clockins) => {
        if (!clockins) {
          throw new Error('Service failed to get time clockins')
        }

        set({
          clockins: clockins,
        })
      })
      .catch((error) => {
        if (isCancelledError(error)) {
          logger.debug('Time clockins query was cancelled')
          // We do a return here as we don't want to update the state if the query was cancelled,
          // otherwise it would be an empty array until the last call finishes and return the values
          return
        }

        set({
          clockins: [],
        })

        exceptionMessageHandler(error)
      })
      .finally(() => {
        set(() => ({ fetchingClockins: false }))
      })
  },
})
