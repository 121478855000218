import { StateCreator } from 'zustand'
import { IUnavailabilityRecord } from '@/models'
import { unavailabilityService } from '@/services'
import { enqueueLoadedSnackbar, exceptionMessageHandler } from '@/utils'
import { SharedSlice } from '@/components/sharedSlice'
import { UnavailabilityRecordType } from '@/constants'

// Leave slice types
interface LeaveState {
  unavailabilityRecords: IUnavailabilityRecord[]
  selectedWeeklyUnavailabilityRecord: IUnavailabilityRecord | undefined
}

export interface LeaveSlice extends LeaveState {
  resetLeaveSlice: () => void

  setSelectedWeeklyUnavailabilityRecord: (
    selectedWeeklyUnavailabilityRecord: IUnavailabilityRecord | undefined,
  ) => void

  /**
   * Get Unavailabilities records for a staff member
   * @param staffMemberId StaffMember id to get the unavailabilities from
   * @param unavailabilityStatus optional filter for the unavailability status
   * @returns Promise<Unavailability[]>
   */
  getUnavailabilitiesByStaffMemberId: (
    staffMemberId?: string,
    unavailabilityStatus?: UnavailabilityRecordType,
  ) => Promise<IUnavailabilityRecord[]>

  /**
   * Create an unavailability record
   * @param unavailability to create
   * @param successMessage message to display on success
   * @param errorMessage message to display on error
   * @returns Promise<Unavailability>
   */
  addUnavailability: (
    unavailability: IUnavailabilityRecord,
    successMessage?: string,
    errorMessage?: string,
  ) => Promise<IUnavailabilityRecord | null>

  /**
   * Cancel an unavailability record
   * @param id of the unavailability record to remove
   * @param successMessage message to display on success
   * @param errorMessage message to display on error
   * @returns Promise<void>
   */
  cancelUnavailability: (
    id: string,
    successMessage?: string,
    errorMessage?: string,
  ) => Promise<void>

  /**
   * Resend an unavailability record
   * @param unavailabilityRecordId to resend
   * @param successMessage message to display on success
   * @param errorMessage message to display on error
   * @returns Promise<void>
   */
  resendUnavailability: (
    unavailabilityRecordId: string,
    successMessage?: string,
    errorMessage?: string,
  ) => Promise<void>
}

const initialState: LeaveState = {
  unavailabilityRecords: [],
  selectedWeeklyUnavailabilityRecord: undefined,
}

export const createLeaveSlice: StateCreator<
  LeaveSlice & SharedSlice,
  [['zustand/devtools', never]],
  [],
  LeaveSlice
> = (set, get) => ({
  ...initialState,

  resetLeaveSlice: () => set(initialState),

  setSelectedWeeklyUnavailabilityRecord: (
    selectedWeeklyUnavailabilityRecord: IUnavailabilityRecord | undefined,
  ) => set({ selectedWeeklyUnavailabilityRecord }),

  getUnavailabilitiesByStaffMemberId: async (
    staffMemberId?: string,
    unavailabilityStatus?: UnavailabilityRecordType,
  ): Promise<IUnavailabilityRecord[]> => {
    if (!staffMemberId) return []

    return unavailabilityService
      .getByStaffMemberId(staffMemberId, unavailabilityStatus)
      .then((result) => {
        set(() => ({
          unavailabilityRecords: result,
        }))
        return result
      })
      .catch((error) => {
        set(() => ({
          unavailabilityRecords: [],
        }))
        exceptionMessageHandler(
          error,
          'Failed to retrieve unavailabily records',
        )
        return []
      })
  },

  addUnavailability: async (
    unavailability: IUnavailabilityRecord,
    successMessage?: string,
    errorMessage?: string,
  ) => {
    set(() => ({ isLoading: true }))

    return unavailabilityService
      .add(unavailability)
      .then(async (createdUnavailability) => {
        if (!createdUnavailability) {
          throw new Error('Service failed to create unavailability record')
        }

        // await unavailabilityService.getByStaffMemberId(createdUnavailability.staffMemberId)
        await get().getUnavailabilitiesByStaffMemberId(
          createdUnavailability.staffMemberId,
        )

        enqueueLoadedSnackbar(
          successMessage ?? 'Unavailability record created',
          'success',
        )

        return createdUnavailability
      })
      .catch((error) => {
        exceptionMessageHandler(
          error,
          errorMessage ?? 'Failed to create the unavailability record',
        )
        return null
      })
      .finally(() => {
        set(() => ({ isLoading: false }))
      })
  },

  cancelUnavailability: async (
    id: string,
    successMessage?: string,
    errorMessage?: string,
  ) => {
    set(() => ({ isLoading: true }))

    return unavailabilityService
      .cancel(id)
      .then((cancelledUnavailability) => {
        if (!cancelledUnavailability) {
          throw new Error('Service failed to cancel the unavailability record')
        }

        enqueueLoadedSnackbar(
          successMessage ?? 'Unavailability record deleted',
          'success',
        )

        set((state) => ({
          unavailabilityRecords: state.unavailabilityRecords.map((record) =>
            record.id === cancelledUnavailability.id
              ? cancelledUnavailability
              : record,
          ),
        }))
      })
      .catch((error) => {
        exceptionMessageHandler(
          error,
          errorMessage ?? 'Failed to delete the unavailability record',
        )
      })
      .finally(() => {
        set(() => ({ isLoading: false }))
      })
  },

  resendUnavailability: async (
    unavailabilityRecordId: string,
    successMessage?: string,
    errorMessage?: string,
  ) => {
    set(() => ({ isLoading: true }))

    return unavailabilityService
      .sendRequest(unavailabilityRecordId)
      .then((updatedUnavailabilityRecord) => {
        if (!updatedUnavailabilityRecord) {
          throw new Error('Service failed to update the unavailability record')
        }

        enqueueLoadedSnackbar(
          successMessage ?? 'Request has been resent',
          'success',
        )
      })
      .catch((error) => {
        exceptionMessageHandler(
          error,
          errorMessage ?? 'Failed to resend the unavailability request',
        )
      })
      .finally(() => {
        set(() => ({ isLoading: false }))
      })
  },
})
