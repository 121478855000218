import { FC, ReactNode, SyntheticEvent, useState } from 'react'
import {
  styled,
  Typography,
  AccordionProps,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummaryProps,
  AccordionDetailsProps,
} from '@mui/material'
import { ExpandMoreRounded as ExpandMoreRoundedIcon } from '@mui/icons-material'

type AccordionItem = {
  id: string
  title: ReactNode
  content: ReactNode
  defaultExpanded?: boolean
  accordionProps?: Omit<AccordionProps, 'children'>
  accordionSummaryProps?: AccordionSummaryProps
  accordionDetailsProps?: AccordionDetailsProps
}

type LoadedAccordionProps = {
  items: AccordionItem[]
  expandOneAtATime?: boolean
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,

  '&:not(.Mui-expanded)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '&:not(:last-child) &.Mui-expanded': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '.MuiAccordion-root&:not(:last-child)': {
    borderBottom: '0px',
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreRoundedIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: `1px solid ${theme.palette.divider}`,
}))

const LoadedAccordion: FC<LoadedAccordionProps> = ({
  items,
  expandOneAtATime = false,
}) => {
  const [expanded, setExpanded] = useState<string | false>(
    items.find((item) => item.defaultExpanded)?.id ?? false,
  )

  const handleChange =
    (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <>
      {items.map((item) => {
        return (
          <Accordion
            key={item.id}
            defaultExpanded={item.defaultExpanded}
            {...(expandOneAtATime && {
              expanded: expanded === item.id,
              onChange: handleChange(item.id),
            })}
            {...item.accordionProps}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreRoundedIcon />}
              aria-controls={`panel-${item.id}-content`}
              id={`panel-${item.id}-header`}
              {...item.accordionSummaryProps}
            >
              {typeof item.title === 'string' ? (
                <Typography variant="body1">{item.title}</Typography>
              ) : (
                item.title
              )}
            </AccordionSummary>
            <AccordionDetails {...item.accordionDetailsProps}>
              {item.content}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

export default LoadedAccordion
