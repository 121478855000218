import { getActiveCompanySettings } from '@/utils'
import dayjs, { Dayjs } from 'dayjs'

/**
 * Helper function to convert time string to the TimeOnly format required for the API
 * @param timeString time string with format 'HH:mm'
 * @returns
 */
export const convertTimeToTimeOnlyFormat = (timeString: string) => {
  const timeWithSeconds = dayjs(timeString, 'HH:mm:ss')

  if (timeWithSeconds.isValid()) {
    return timeWithSeconds.format('HH:mm:ss')
  }

  const timeWithoutSeconds = dayjs(timeString, 'HH:mm')

  return timeWithoutSeconds.format('HH:mm:ss')
}

/**
 * Helper function to convert a Dayjs object to the DateOnly format required for the API
 * @param date Dayjs object
 * @returns string in the format YYYY-MM-DD
 */
export const convertTimeToDateOnlyFormat = (date: Dayjs) => {
  return date.format('YYYY-MM-DD')
}

/**
 * Get the range of the current week (Monday to Sunday).
 * @returns {[Dayjs, Dayjs]} A tuple containing the Monday and Sunday of the current week.
 */
export const getCurrentMondayToSundayRange = (date: Dayjs): [Dayjs, Dayjs] => {
  const currentDay = date.day() // Day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)

  // Calculate the difference from today to Monday
  const daysToMonday = currentDay === 0 ? -6 : 1 - currentDay

  // Calculate Monday and Sunday
  const monday = date
    .add(daysToMonday, 'day')
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)

  return [monday, monday.add(6, 'day')]
}

export const setCompanyDayStartAndTimeZone = (date: Dayjs) => {
  // On page load it will always be the current week
  const { dayStartPreference, timeZonePreference } = getActiveCompanySettings()
  const [hours, minutes, seconds] = dayStartPreference.split(':').map(Number)

  const dateRangeWithCompanyStartTime: Dayjs = date
    .set('hour', hours)
    .set('minute', minutes)
    .set('second', seconds)
    .set('milliseconds', 0)

  return dateRangeWithCompanyStartTime.tz(timeZonePreference)
}
