export const DATE_TIME_REPORTS = 'ddd Do MMM, h:mma' //Wed 1st Nov, 11:10 am
export const DATE_REPORTS = 'ddd Do MMM. YYYY' //Wed 1st Nov. 2023

//DATE TIME FORMATS
export const LONG_DATE_TIME_FORMAT = 'D MMMM YYYY, h:mma' // 1 November 2023, 5:00 am
export const SHORT_DATE_TIME_FORMAT = 'D MMM YYYY, h:mma' // 1 Nov 2023, 5:00 am
export const SLASHED_DATE_TIME_FORMAT = 'D/M/YYYY h:mma' // 1/11/2024 5:00AM

//DATE FORMATS
export const LONG_DATE_FORMAT = 'dddd, Do MMMM YYYY' //Friday, 17th November 2023
export const MEDIUM_DATE_FORMAT = 'D MMMM YYYY' // 1 November 2023
export const MEDIUM_DATE_NO_YEAR_FORMAT = 'Do MMMM' // 1st November
export const SHORT_DATE_FORMAT = 'D MMM YYYY' // 1 Nov 2023
export const DAY_DATE_FORMAT = 'ddd D MMM' // Fri 1 Nov
export const DATE_AND_SHORT_MONTH_FORMAT = 'D MMM' // 1 Nov

//TIME FORMATS
export const TIME_FORMAT = 'h:mma' // 5:10 am

export const DateTimeFormats = {
  DATE_TIME_REPORTS,
  DATE_REPORTS,
  LONG_DATE_TIME_FORMAT,
  SHORT_DATE_TIME_FORMAT,
  LONG_DATE_FORMAT,
  MEDIUM_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  TIME_FORMAT,
  SLASHED_DATE_TIME_FORMAT,
}

export enum RangeFilter {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Custom = 'custom',
}

export enum DaysOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
