import { coreApi } from '@/api'
import { IClockinResponse } from '@/models'
import { fetchWithValidation, singletonQueryClient } from '@/utils'
import { Dayjs } from 'dayjs'

enum QueryKeys {
  GetClockins = 'time-clockins',
}

/**
 * Get clockins by start time and end time
 * @param startTime - start time to get the clockins for
 * @param endTime - end time to get the clockins for
 * @param includeInactive - include inactive clockins
 * @param includeLiveCalculation - include live calculation clockins
 * @returns Promise<IClockinResponse[]>
 */
export const getClockins = (
  startTime: Dayjs,
  endTime: Dayjs,
  includeInactive: boolean = false,
  includeLiveCalculation: boolean = false,
): Promise<IClockinResponse[]> => {
  return singletonQueryClient.fetchQuery({
    queryKey: [QueryKeys.GetClockins, {}],
    queryFn: async ({ signal }) => {
      const clockins = await fetchWithValidation(() =>
        coreApi.getClockins(
          startTime,
          endTime,
          includeInactive,
          includeLiveCalculation,
          signal,
        ),
      )

      return clockins ?? []
    },
  })
}
