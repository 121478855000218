import { coreApi } from '@/api'
import { fetchWithValidation, singletonQueryClient } from '@/utils'
import { UnavailabilityRecordType, UnavailabilityStatus } from '@/constants'
import {
  IUnavailabilityRecord,
  UnavailabilityRecord,
  UnavailabilityRecordUpdateRequest,
} from '@/models'

enum QueryKeys {
  getByStaffMemberId = 'unavailability',
}

/**
 * Get details of a staff members unavailability
 * @param staffMemberId - staff member to get the unavailability records for
 * @param unavailabilityStatus - optional filter for the unavailability status
 * @returns Promise<IUnavailabilityRecord[]>
 */
export const getByStaffMemberId = async (
  staffMemberId: string,
  unavailabilityStatus?: UnavailabilityRecordType,
  from?: string,
): Promise<IUnavailabilityRecord[]> => {
  return singletonQueryClient.fetchQuery({
    queryKey: [
      QueryKeys.getByStaffMemberId,
      staffMemberId,
      unavailabilityStatus,
    ],
    queryFn: async () => {
      const unavailabilityRecords = await fetchWithValidation(() =>
        coreApi.getUnavailability(staffMemberId, unavailabilityStatus, from),
      )

      return unavailabilityRecords
    },
  })
}

/**
 * Create an unavailability record
 * @param unavailability to create
 * @returns Promise<Unavailability>
 */
export const add = async (
  unavailability: IUnavailabilityRecord,
): Promise<IUnavailabilityRecord> => {
  const result = await fetchWithValidation(() =>
    coreApi.addUnavailability(unavailability as UnavailabilityRecord),
  )

  return result
}

export const cancel = async (id: string): Promise<IUnavailabilityRecord> => {
  return await fetchWithValidation(() =>
    coreApi.updateUnavailabilityStatus(
      id,
      new UnavailabilityRecordUpdateRequest({
        status: UnavailabilityStatus.Cancelled,
      }),
    ),
  )
}

/**
 * Update an unavailability record status
 * @param id Id of the unavailability record
 * @param status Status to update
 * @returns Promise<IUnavailabilityRecord>
 */
export const updateStatus = async (
  id: string,
  status: UnavailabilityStatus,
): Promise<IUnavailabilityRecord> => {
  const payload = {
    status: status,
  } as UnavailabilityRecordUpdateRequest
  return await fetchWithValidation(() =>
    coreApi.updateUnavailabilityStatus(id, payload),
  )
}

/**
 * Send an existing unavailability request by id
 * @param id Id of the request to send
 * @returns Promise<IUnavailabilityRecord>
 */
export const sendRequest = async (
  id: string,
): Promise<IUnavailabilityRecord> => {
  const result = await fetchWithValidation(() =>
    coreApi.sendUnavailabilityApprovalRequest(id),
  )
  return result
}
