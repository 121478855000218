import { Locale } from './enums'

type CountryLocaleOptions = { [key: string]: LocaleOptions }
export type LocaleOptions = { locale: string; currency: string }
export const CountryLocaleOptions: CountryLocaleOptions = {
  default: {
    locale: Locale.NZ,
    currency: 'NZD',
  },
  'en-NZ': {
    locale: Locale.NZ,
    currency: 'NZD',
  },
  'en-AU': {
    locale: Locale.AU,
    currency: 'AUD',
  },
  'en-GB': {
    locale: Locale.UK,
    currency: 'GBP',
  },
  'en-IE': {
    locale: Locale.IE,
    currency: 'EUR',
  },
}
