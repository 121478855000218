import { getActiveCompanySettings, logger } from '@/utils'
import { Dayjs } from 'dayjs'
import { defer } from 'react-router-dom'
import { initialState as shiftsSliceInitialState } from '../shiftsSlice'
import useAppStore from '@/state/store'

export default async function loader() {
  logger.debug('[loader] Roster loader')

  // On page load it will always be the current week
  const { dayStartPreference } = getActiveCompanySettings()
  const [hours, minutes, seconds] = dayStartPreference.split(':').map(Number)

  const dateWithCompanyStartTime: Dayjs =
    shiftsSliceInitialState.rosterSelectedDate
      .set('hour', hours)
      .set('minute', minutes)
      .set('second', seconds)
      .set('milliseconds', 0)

  const appStoreState = useAppStore.getState()

  appStoreState.setRosterSelectedDate(dateWithCompanyStartTime)

  // If the dateWithCompanyStartTime is within the selected date range on the previous screen (My Shifts)
  // and there are rostered shifts for that date, we don't need to fetch them again
  const rosteredShifts = appStoreState.getRosteredShiftsByDateRange([
    dateWithCompanyStartTime,
    dateWithCompanyStartTime,
  ])

  const staffMembers = appStoreState.getStaffMembers(undefined, true)

  return defer({
    data: Promise.all([rosteredShifts, staffMembers])
      .then(() => true)
      .catch((error) => {
        logger.error('Roster Promise.all error', error)
        return false
      }),
  })
}
