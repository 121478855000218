import { coreApi } from '@/api'
import { IRosteredShiftResponse } from '@/models'
import { fetchWithValidation, singletonQueryClient } from '@/utils'
import { Dayjs } from 'dayjs'

enum QueryKeys {
  GetRosteredShifts = 'rostered-shifts',
}

/**
 * Get rostered shifts by roster id or date range
 * @param rosterId - optional roster id to get the rostered shifts for
 * @param startTime - optional start time to get the rostered shifts for
 * @param endTime - optional end time to get the rostered shifts for
 * @param includeDeleted - optional include deleted flag to get the rostered shifts for
 * @param includeUnpublishedShifts - optional include unpublished shifts flag to get the rostered shifts for. Defaults to true
 * @returns Promise<IRosteredShiftResponse[]>
 */
export const getRosteredShifts = (
  rosterId: string | undefined,
  startTime: Dayjs,
  endTime: Dayjs,
  includeDeleted: boolean = false,
  includeUnpublishedShifts: boolean = true,
): Promise<IRosteredShiftResponse[]> => {
  return singletonQueryClient.fetchQuery({
    queryKey: [
      QueryKeys.GetRosteredShifts,
      {
        rosterId,
        startTime,
        endTime,
        includeDeleted,
        includeUnpublishedShifts,
      },
    ],
    queryFn: async ({ signal }) => {
      const rosteredShifts = await fetchWithValidation(() =>
        coreApi.getRosteredShifts(
          rosterId,
          startTime,
          endTime,
          includeDeleted,
          includeUnpublishedShifts,
          signal,
        ),
      )

      return rosteredShifts ?? []
    },
  })
}
