import useAppStore from '@/state/store'
import { logger } from '@/utils'
import { defer } from 'react-router-dom'

export default async function loader() {
  logger.debug('[loader] Team loader')

  const staffMembers = useAppStore.getState().getStaffMembers(undefined, false)

  return defer({
    data: Promise.all([staffMembers])
      .then(() => true)
      .catch((error) => {
        logger.error('Team Promise.all error', error)
        return false
      }),
  })
}
