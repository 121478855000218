//TODO: Remove this once we are no longer dependent on MercuryAPI
/**
 * This file and its methods have been added because it was not possible to return
 * a union type in the automatically generated file from NSwag or through the Swashbuckle
 * specifications. PLEASE DO NOT USE THESE METHODS UNLESS ABSOLUTELY NECESSARY.
 * They are specifically implemented to support retrieving the staff member, which
 * the only case in this system where a polymorphic response is required.
 */

import { QueryOptions } from '@tanstack/react-query'
import api from './api'
import appConfig from '@/appConfig'

/**
 * Execute a GET query against the Mercury Api.
 *
 * @param path the path of endpoint
 * @param options QueryOptions from React Query (without queryKey and queryFn)
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function get<T>(
  path: string,
  options?: Omit<QueryOptions<T>, 'queryKey' | 'queryFn'>,
  signal?: AbortSignal,
) {
  return await api.get(appConfig.mercuryApiUrl, path, options, signal)
}

/**
 * Execute a POST against Mercury Api.
 *
 * @param path the path of the endpoint
 * @param requestBody the body of the POST request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function add(path: string, requestBody: object, signal?: AbortSignal) {
  return await api.add(appConfig.mercuryApiUrl, path, requestBody, signal)
}

/**
 * Execute a PUT against Mercury Api.
 *
 * @param path the path of the endpoint
 * @param requestBody the body of the PUT request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function update(path: string, requestBody: object, signal?: AbortSignal) {
  return await api.update(appConfig.mercuryApiUrl, path, requestBody, signal)
}

/**
 * Execute a DELETE against Mercury Api.
 *
 * @param path the path of the endpoint
 * @param requestBody the body of the DELETE request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function remove(
  path: string,
  requestBody?: object,
  signal?: AbortSignal,
) {
  return await api.remove(appConfig.mercuryApiUrl, path, requestBody, signal)
}

/**
 * Execute a PATCH against Mercury Api.
 *
 * @param path the path of the endpoint
 * @param requestBody the body of the PATCH request
 * @param signal to be able to abort the api call
 * @returns the data parsed from the JSON response received
 */
async function patch(path: string, requestBody: object, signal?: AbortSignal) {
  return await api.patch(appConfig.mercuryApiUrl, path, requestBody, signal)
}

/**
 * @deprecated MercuryAPI is deprecated and should be avoided.
 * Use CoreAPI or another replacement instead.
 */
export default {
  get,
  add,
  update,
  remove,
  patch,
}
