export class LoadedError extends Error {
  id: string
  code: number
  details: string[]

  constructor(
    id: string,
    code: number,
    message: string,
    details: string[] = [],
  ) {
    super(message)
    this.name = 'LoadedError'

    this.id = id
    this.code = code
    this.details = details
  }
}
